import React from "react"
import { Link } from "gatsby"

import PageLayout from "../components/page-layout"
import SEO from "../components/seo"
import Style from "./404.module.scss"

const NotFoundPage = ({ data }) => (
  <PageLayout
    title="存在しないページ"
    enTitle="404 NOT FOUND"
  >
    <SEO title="404: Not found" />
    <div className={Style.notFound}>
      <h1>ページが見つかりませんでした</h1>
      <div className={Style.wrapper}>
        <Link to="/">トップページ</Link>
      </div>
    </div>
  </PageLayout>
)

export default NotFoundPage
